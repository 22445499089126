import "./DonorDetails.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Notification from "../Notification/Notification";
import { useMutation } from "@apollo/client";
import { ADD_DONOR_DATA } from "../../gql_queries/Queries";

function DonorDetails() {
  const [showNotification, setShowNotification] = useState(false);
  const [donorName, setDonarName] = useState("");
  const initialvalues = {
    fullName: "",
    phoneNumber: "",
    bloodGroup: "",
    location: "",
  };
  const [addDonorData, setAddDonorData] = useState(initialvalues);
  const [errorMessage, setErrorMessage] = useState('')
  const addDonor = useMutation(ADD_DONOR_DATA);
  const saveDonor = () => {
    if (
      addDonorData?.fullName?.length > 0 &&
      addDonorData?.phoneNumber?.length > 0 &&
      addDonorData?.location?.length > 0 && 
      addDonorData?.bloodGroup?.length > 0
    ) {
      setErrorMessage('')
      addDonor[0]({
        variables: {
          name: addDonorData?.fullName,
          number: addDonorData?.phoneNumber,
          place: addDonorData?.location,
          bloodGroup: addDonorData?.bloodGroup,
        },
      }).then((response) => {
        setDonarName(response?.data?.insert_donarData?.returning[0]?.name);
        setShowNotification(true);
        setTimeout(() => setShowNotification(false), 5000);
        setAddDonorData(initialvalues);
      });
    } else {
      setErrorMessage('please fill mandatory fields')
    }
  };

  const handleChange = (e) => {
    setAddDonorData({ ...addDonorData, [e.target.name]: e.target.value });
  };

  return (
    <>
      {showNotification && <Notification DonorName={donorName} />}
      <div className="container donorDetailsRow">
        <p className="jointeam">Join Our Team</p>
        <span className="text-xs text-red-600">{errorMessage}</span>
        <div className="formRow">
          <input
            type="text"
            name="fullName"
            placeholder="Enter your name"
            value={addDonorData?.name}
            onChange={handleChange}
          />
        </div>
        <div className="formRow">
          <input
            type="text"
            name="phoneNumber"
            placeholder="Enter your phone number"
            value={addDonorData?.phoneNumber}
            onChange={handleChange}
          />
        </div>
        <div className="formRow">
          <select
            name="bloodGroup"
            id="bloodGroup"
            value={addDonorData?.bloodGroup}
            onChange={handleChange}
          >
            <option hidden>Select Blood Group</option>
            <option value="A+ve">A+ve</option>
            <option value="O+ve">O+ve</option>
            <option value="B+ve">B+ve</option>
            <option value="AB+ve">AB+ve</option>
            <option value="A-ve">A-ve</option>
            <option value="O-ve">O-ve</option>
            <option value="B-ve">B-ve</option>
            <option value="AB-ve">AB-ve</option>
          </select>
        </div>
        <div className="formRow">
          <select
            name="location"
            id="Location"
            value={addDonorData?.location}
            onChange={handleChange}
          >
            <option hidden>Select Location</option>
            <option value="Vadanamkurussi">Vadanamkurussi</option>
            <option value="Karakkad">Karakkad</option>
            <option value="Ongallur">Ongallur</option>
            <option value="Maruthur">Maruthur</option>
            <option value="Pattambi">Pattambi</option>
            <option value="Sankaramangalam">Sankaramangalam</option>
            <option value="Amayur">Amayur</option>
            <option value="Koppam">Koppam</option>
            <option value="Vilayur">Vilayur</option>
            <option value="Kooraachippadi">Kooraachippadi</option>
            <option value="Naduvattam">Naduvattam</option>
            <option value="Thiruvegappura">Thiruvegappura</option>
            <option value="Paruthur">Paruthur</option>
            <option value="Kulamukk">Kulamukk</option>
            <option value="Muthuthala">Muthuthala</option>
            <option value="Perumudiyoor">Perumudiyoor</option>
          </select>
        </div>
        <div className="formRow">
          <button className="joinTeamBtn" onClick={saveDonor}>
            Join the Team
          </button>
        </div>
        <Link to="/" className="findDonorLink">
          Find Your Doner
        </Link>
      </div>
    </>
  );
}

export default DonorDetails;
