import React from "react";
import "./ResultFilter.css";

function ResultFilter({ place, bloodGroup, setplace, setBloodGroup }) {
  return (
    <>
      <div className="container resultFilter">
        <p>Search Result for</p>
        <div className="resultFilter-row">
          <select
            name="Blood Group"
            id="bloodGroup"
            value={bloodGroup}
            onChange={(e) => setBloodGroup(e.target.value)}
          >
            <option value="A+ve">A+ve</option>
            <option value="O+ve">O+ve</option>
            <option value="B+ve">B+ve</option>
            <option value="AB+ve">AB+ve</option>
            <option value="A-ve">A-ve</option>
            <option value="O-ve">O-ve</option>
            <option value="B-ve">B-ve</option>
            <option value="AB-ve">AB-ve</option>
          </select>
          <select
            name="Location"
            id="Location"
            value={place}
            onChange={(e) => setplace(e.target.value)}
          >
            <option hidden>Select Location</option>
            <option value="Vadanamkurussi">Vadanamkurussi</option>
            <option value="Karakkad">Karakkad</option>
            <option value="Ongallur">Ongallur</option>
            <option value="Maruthur">Maruthur</option>
            <option value="Pattambi">Pattambi</option>
            <option value="Sankaramangalam">Sankaramangalam</option>
            <option value="Amayur">Amayur</option>
            <option value="Koppam">Koppam</option>
            <option value="Vilayur">Vilayur</option>
            <option value="Kooraachippadi">Kooraachippadi</option>
            <option value="Naduvattam">Naduvattam</option>
            <option value="Thiruvegappura">Thiruvegappura</option>
            <option value="Paruthur">Paruthur</option>
            <option value="Kulamukk">Kulamukk</option>
            <option value="Muthuthala">Muthuthala</option>
            <option value="Perumudiyoor">Perumudiyoor</option>
          </select>
        </div>
      </div>
    </>
  );
}

export default ResultFilter;
