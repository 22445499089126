import React from "react";
import DonorDetails from "../../Components/DonorDetails/DonorDetails";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";

function AddDonor({ isLoggedIn, LogOutBtnClick }) {
  return (
    <>
      <div className="d-flex flex-column min-vh-100">
        <Header isLoggedIn={isLoggedIn} LogOutBtnClick={LogOutBtnClick} />
        <DonorDetails />
        <Footer />
      </div>
    </>
  );
}

export default AddDonor;
