import React, { useEffect } from "react";
import "./SearchContent.css";
import { Link, useNavigate } from "react-router-dom";
import BloodUrl from "../../assets/image.svg";
import { useState } from "react";

function SearchContent({ setBloodGroup, bloodGroup, setplace, place }) {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  let navigate = useNavigate();
  useEffect(() => {
    setBloodGroup("");
    setplace("");
  }, []);

  const search = () => {
    if (place && bloodGroup) {
      navigate(`/result`);
    } else {
      setShowErrorMessage(true);
      setTimeout(() => setShowErrorMessage(false), 5000);
    }
  };
  return (
    <>
      <div className="container search-content-row">
        <div className="image">
          <img src={BloodUrl} alt="Banner" />
        </div>
        <div className="search-wrapper">
          <div className="search">
            <p>Search Available doner’s</p>
            {showErrorMessage && (
              <span className="errorMessage">All fields are mandatory</span>
            )}
            <select
              name="Blood Group"
              id="bloodGroup"
              value={bloodGroup}
              onChange={(e) => setBloodGroup(e.target.value)}
            >
              <option hidden>Select Blood Group</option>
              <option value="A+ve">A+ve</option>
              <option value="O+ve">O+ve</option>
              <option value="B+ve">B+ve</option>
              <option value="AB+ve">AB+ve</option>
              <option value="A-ve">A-ve</option>
              <option value="O-ve">O-ve</option>
              <option value="B-ve">B-ve</option>
              <option value="AB-ve">AB-ve</option>
            </select>
            <select
              name="Location"
              id="Location"
              value={place}
              onChange={(e) => setplace(e.target.value)}
            >
              <option hidden>Select Location</option>
              <option value="Vadanamkurussi">Vadanamkurussi</option>
              <option value="Karakkad">Karakkad</option>
              <option value="Ongallur">Ongallur</option>
              <option value="Maruthur">Maruthur</option>
              <option value="Pattambi">Pattambi</option>
              <option value="Sankaramangalam">Sankaramangalam</option>
              <option value="Amayur">Amayur</option>
              <option value="Koppam">Koppam</option>
              <option value="Vilayur">Vilayur</option>
              <option value="Kooraachippadi">Kooraachippadi</option>
              <option value="Naduvattam">Naduvattam</option>
              <option value="Thiruvegappura">Thiruvegappura</option>
              <option value="Paruthur">Paruthur</option>
              <option value="Kulamukk">Kulamukk</option>
              <option value="Muthuthala">Muthuthala</option>
              <option value="Perumudiyoor">Perumudiyoor</option>
            </select>
            <button className="Searchbtn" onClick={search}>
              Search
            </button>
            <Link to="/add-donor" className="donorLink">
              Are you a Doner?
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchContent;
