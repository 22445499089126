import React, { useEffect } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import LeftBanner from "../../Components/LeftBanner/LeftBanner";
import LoginForm from "../../Components/LoginForm/LoginForm";
import { useNavigate } from "react-router-dom";

function Login({setIsLoggedIn}) {
  let navigate = useNavigate();
  const user = 'dyfipattambi'
  const pswd = 'Now3@Ludhiana'
  useEffect(() => {
    setIsLoggedIn(false);
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setIsLoggedIn(true);
      navigate(`/`);
    }
  }, []);
  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <div className="flex items-center flex-1">
        <LeftBanner />
        <LoginForm user={user} pswd={pswd}/>
      </div>
      <Footer />
    </div>
  );
}

export default Login;
