import React, { useEffect, useState } from "react";
import "./EditUserPopup.css";
import BloodUrl from "../../assets/image.svg";
import CloseIcon from "../../assets/CloseIcon.svg";

function EditUserPopup({
  setPopupShow,
  selectedData,
  DeleteUserClick,
  EditUserClick,
}) {
  const initialvalues = {
    fullName: "",
    phoneNumber: "",
    bloodGroup: "",
    location: "",
    id: "",
  };
  const [editedDonorData, setEditedDonorData] = useState(initialvalues);
  const handleChange = (e) => {
    setEditedDonorData({ ...editedDonorData, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    setEditedDonorData({
      fullName: selectedData?.name,
      phoneNumber: selectedData?.number,
      bloodGroup: selectedData?.blood_group,
      location: selectedData?.place,
      id: selectedData?.id,
    });
  }, []);

  return (
    <>
      <div className="popupOverlay">
        <div className="popup">
          <div className="popupHeader">
            <h6>Edit Donor's profile</h6>
            <img onClick={() => setPopupShow(false)} src={CloseIcon}></img>
          </div>
          <div className="popupRow">
            <div className="popupRowImage">
              <img src={BloodUrl} alt="Banner" />
            </div>
            <div className="popupRowRightMd">
              <div className="formRow">
                <input
                  type="text"
                  name="fullName"
                  placeholder="Enter your name"
                  value={editedDonorData?.fullName}
                  onChange={handleChange}
                ></input>
                <input
                  type="text"
                  name="phoneNumber"
                  placeholder="Enter your phone number"
                  value={editedDonorData?.phoneNumber}
                  onChange={handleChange}
                ></input>
                <select
                  value={editedDonorData?.bloodGroup}
                  onChange={handleChange}
                  name="bloodGroup"
                  id="bloodGroup"
                >
                  <option hidden>Select Blood Group</option>
                  <option value="A+ve">A+ve</option>
                  <option value="O+ve">O+ve</option>
                  <option value="B+ve">B+ve</option>
                  <option value="AB+ve">AB+ve</option>
                  <option value="A-ve">A-ve</option>
                  <option value="O-ve">O-ve</option>
                  <option value="B-ve">B-ve</option>
                  <option value="AB-ve">AB-ve</option>
                </select>
                <select
                  value={editedDonorData?.location}
                  onChange={handleChange}
                  name="location"
                  id="Location"
                >
                  <option hidden>Select Location</option>
                  <option value="Vadanamkurussi">Vadanamkurussi</option>
                  <option value="Karakkad">Karakkad</option>
                  <option value="Ongallur">Ongallur</option>
                  <option value="Maruthur">Maruthur</option>
                  <option value="Pattambi">Pattambi</option>
                  <option value="Sankaramangalam">Sankaramangalam</option>
                  <option value="Amayur">Amayur</option>
                  <option value="Koppam">Koppam</option>
                  <option value="Vilayur">Vilayur</option>
                  <option value="Kooraachippadi">Kooraachippadi</option>
                  <option value="Naduvattam">Naduvattam</option>
                  <option value="Thiruvegappura">Thiruvegappura</option>
                  <option value="Paruthur">Paruthur</option>
                  <option value="Kulamukk">Kulamukk</option>
                  <option value="Muthuthala">Muthuthala</option>
                  <option value="Perumudiyoor">Perumudiyoor</option>
                </select>
              </div>
              <div className="btnRow">
                <button
                  onClick={() => DeleteUserClick()}
                  type="submit"
                  className="delete"
                >
                  Delete User
                </button>
                <button
                  onClick={() => EditUserClick(editedDonorData)}
                  type="submit"
                  className="save"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditUserPopup;
