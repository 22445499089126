import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

const client = new ApolloClient({
  uri: 'https://alive-emu-46.hasura.app/v1/graphql',
  cache: new InMemoryCache(),
  headers: {
    "x-hasura-admin-secret": `Wu6rKzi052XnyATdeCi7J5Er7BOZe65fEBfGFdzBVX1YiMKBiteBz34X0AZd2HhK`
  },
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </React.StrictMode>
  </BrowserRouter>
);


reportWebVitals();
