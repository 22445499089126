import React from "react";
import "./Header.css";

import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";

function Header({ isLoggedIn, LogOutBtnClick }) {
  return (
    <div className="container position-relative">
      <div className={` ${isLoggedIn ? 'flex-col-mob header': 'header'}`}>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>

        {isLoggedIn ? (
          <ul className="menu">
            <li><Link to='/'>Search Donor</Link></li>
            <li><Link to='/dashboard'>Dashboard</Link></li>
            <li>
              {" "}
              <div className="cursor-pointer" onClick={LogOutBtnClick}>
                Logout
              </div>
            </li>
          </ul>
        ) : (
          <ul className="menu">
            <li> <a href="https://wa.me/919446533141">Contact us</a></li>
          </ul>
        )}
      </div>
    </div>
  );
}

export default Header;
