import React from 'react'
import './Notification.css'

function Notification({DonorName}) {

  return (
    <>
    <div className="notification">
        <h6>Thank you {DonorName}</h6>
        <p>You are Successfuly joined our team</p>
    </div>
    </>
  )
}

export default Notification