import React, { useEffect } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import ResultBox from "../../Components/ResultBox/ResultBox";
import ResultFilter from "../../Components/ResultFilter/ResultFilter";
import { useNavigate } from "react-router-dom";

import { GET_DONOR_DATA } from "../../gql_queries/Queries";
import { useQuery } from "@apollo/client";

function SearchResult({
  place,
  bloodGroup,
  setplace,
  setBloodGroup,
  isLoggedIn,
  LogOutBtnClick,
}) {
  let navigate = useNavigate();

  useEffect(() => {
    if (!place || !bloodGroup) {
      navigate(`/`);
    }
  }, []);

  const { loading, data } = useQuery(GET_DONOR_DATA, {
    variables: {
      place: place,
      bloodGroup: bloodGroup,
    },
  });

  return (
    <>
      <div className="d-flex flex-column min-vh-100">
        <Header isLoggedIn={isLoggedIn} LogOutBtnClick={LogOutBtnClick} />
        <div className="flex-fill">
          <ResultFilter
            bloodGroup={bloodGroup}
            place={place}
            setBloodGroup={setBloodGroup}
            setplace={setplace}
          />
          <ResultBox
            Donors={data?.donarData}
            bloodGroup={bloodGroup}
            place={place}
            donorLoading={loading}
          />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default SearchResult;
