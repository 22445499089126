import React, { useEffect, useState } from "react";
import { SUMMARY } from "../../gql_queries/Queries";
import { useQuery } from "@apollo/client";

function SingleRow({ place, index }) {
  const [bloodCount, setBloodCount] = useState({
  });
  const { loading, data } = useQuery(SUMMARY, {
    variables: {
      place : place === "Total" ? "%%" : place,
      bloodGroup: "%%",
    },
  });

  useEffect(() => {
    const bloodGroupCount = {};

    // Count the occurrences of each blood group
    data?.donarData.forEach((donar) => {
      const { blood_group } = donar;
      bloodGroupCount[blood_group] = (bloodGroupCount[blood_group] || 0) + 1;
    });

    setBloodCount(bloodGroupCount)
  }, [data])
  

  

  return (
    <tr key={index} className="bg-[#e0e2ed] p-2 border-t-4 border-white">
      <td className="py-2 px-3 text-center">{index + 1}</td>
      <td className="py-2 px-3">{place}</td>
      <td className="py-2 px-3 text-center">
        {bloodCount['A+ve'] || 0}
      </td>
      <td className="py-2 px-3 text-center">{bloodCount['O+ve'] || 0}</td>
      <td className="py-2 px-3 text-center">{bloodCount['B+ve'] || 0}</td>
      <td className="py-2 px-3 text-center">{bloodCount['AB+ve'] || 0}</td>
      <td className="py-2 px-3 text-center">{bloodCount['A-ve'] || 0}</td>
      <td className="py-2 px-3 text-center">{bloodCount['O-ve'] || 0}</td>
      <td className="py-2 px-3 text-center">{bloodCount['B-ve'] || 0}</td>
      <td className="py-2 px-3 text-center">{bloodCount['AB-ve'] || 0}</td>
      <td className="py-2 px-3 text-center font-semibold">
        {data?.donarData?.length || 0}
      </td>
    </tr>
  );
}

export default SingleRow;
