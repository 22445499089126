import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Search from "./Pages/Search/Search";
import SearchResult from "./Pages/SearchResult/SearchResult";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import AddDonor from "./Pages/AddDonor/AddDonor";
import Login from "./Pages/Login/Login";
import Dashboard from "./Pages/Dashboard/Dashboard";

function App() {
  const [bloodGroup, setBloodGroup] = useState("");
  const [place, setplace] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setIsLoggedIn(true);
    }
  }, []);

  const LogOutBtnClick = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("user");
  };
  return (
    <>
      <div className="bg-bg w-full min-h-screen">
        <Routes>
          <Route
            path="/login"
            element={<Login setIsLoggedIn={setIsLoggedIn} />}
          />
          <Route
            path="/"
            element={
              isLoggedIn ? (
                <Search
                  setBloodGroup={setBloodGroup}
                  bloodGroup={bloodGroup}
                  setplace={setplace}
                  place={place}
                  isLoggedIn={isLoggedIn}
                  LogOutBtnClick={LogOutBtnClick}
                />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/result"
            element={
              isLoggedIn ? (
                <SearchResult
                  setplace={setplace}
                  setBloodGroup={setBloodGroup}
                  place={place}
                  bloodGroup={bloodGroup}
                  isLoggedIn={isLoggedIn}
                  LogOutBtnClick={LogOutBtnClick}
                />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route path="/add-donor" element={<AddDonor  isLoggedIn={isLoggedIn}
                  LogOutBtnClick={LogOutBtnClick} />} />
          <Route path="/admin/login" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              isLoggedIn ? (
                <Dashboard
                  isLoggedIn={isLoggedIn}
                  LogOutBtnClick={LogOutBtnClick}
                />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
        </Routes>
      </div>
    </>
  );
}

export default App;
