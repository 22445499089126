import React from "react";
import "./ResultBox.css";
import LetteredAvatar from "react-lettered-avatar";

import phoneIcon from "../../assets/phone.svg";

function ResultBox({ Donors, bloodGroup, place, donorLoading }) {
  return (
    <>
      <div className="container resultBox">
        {!donorLoading && Donors.length > 0 ? (
          place && bloodGroup ? (
            <p className="result-showing">
              Showing results for <strong>{bloodGroup}</strong>
            </p>
          ) : (
            ""
          )
        ) : (
          ""
        )}
        {donorLoading ? (
          <p className="no-result">Loading...</p>
        ) : (
          <>
            {Donors?.length > 0 ? (
              <>
                {Donors?.map((content, index) => (
                  <div key={index} className="resultRow">
                    <div className="donorImage">
                      <LetteredAvatar name={content.name.charAt(0)} />
                    </div>
                    <div className="name-place">
                      <div className="name">
                        <h6>{content.name}</h6>
                      </div>
                      <div className="place">
                        <p>{content.place}</p>
                      </div>
                    </div>
                    <div className="bloodGroup">
                      <p>{content.blood_group}</p>
                    </div>
                    <div className="phone-availability">
                      <div className="phoneImage">
                        <a href={`tel:+91${content.number}`}>
                          <img src={phoneIcon} alt="Phone Icon" />
                        </a>
                      </div>
                      <div className="contactNumber">
                        <a href={`tel:+91${content.number}`}>
                          {content.number}
                        </a>
                      </div>
                      {content.available === true ? (
                        <div className="availability">
                          <label>Available</label>
                        </div>
                      ) : (
                        <div className="not-availability">
                          <label>Not Available</label>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <p className="no-result">
                No Results for <strong>{bloodGroup}</strong>
              </p>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default ResultBox;
