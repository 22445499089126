import React from "react";
import "./AllDetails.css";
import searchImg from "../../assets/search.svg";
import DetailsSingleRow from "../DetailsSingleRow/DetailsSingleRow";
import Summary from "../Summary/Summary";

function AllDetails({
  edituser,
  userDetails,
  loading,
  setSearchData,
  searchData,
}) {


  return (
    <>
    <div className="hidden md:flex mb-5">
      <Summary userDetails={userDetails?.donarData} />
    </div>
      <div className="sm:flex gap-2 sm:gap-0 sm:flex-row flex-col items-center justify-between pt-2 sm:pt-5">
        <h3 className="text-base">Doner’s list</h3>
        <div className="flex items-center gap-2 justify-end">
          <div className="flex py-1 px-2 gap-2 border border-solid  rounded-md w-96 max-w-[80%] bg-white">
            <img src={searchImg} className="w-5" />
            <input
              type="text"
              name="search"
              placeholder="search"
              className="flex-1 outline-none"
              value={searchData}
              onChange={(e) => {
                setSearchData(e.target.value);
              }}
            ></input>
          </div>
        </div>
      </div>
      <div className="AlldetailsTable">
        <div className="tableHead">
          <div className="name">
            <h6>Name</h6>
          </div>
          <div className="place">
            <h6>Place</h6>
          </div>
          <div className="bloodGroup">
            <h6>Blood group</h6>
          </div>
          <div className="phoneNumber">
            <h6>Phone Number</h6>
          </div>
          <div className="action">
            <h6>Action</h6>
          </div>
        </div>
        {loading && <span className="loading">Loading..</span>}
        {!loading && userDetails?.donarData.length === 0 ? (
          <>
            <p className="no-result mt-4">No Results found</p>
          </>
        ) : (
          <>
            {userDetails?.donarData.map((donarData, index) => (
              <DetailsSingleRow
                donarData={donarData}
                key={index}
                edituser={edituser}
              />
            ))}
          </>
        )}
      </div>
    </>
  );
}

export default AllDetails;
