import React from "react";

import { useEffect } from "react";
import SingleRow from "./SingleRow";


function Summary({ userDetails }) {


  const places = [
    "Vadanamkurussi",
    "Karakkad",
    "Ongallur",
    "Maruthur",
    "Pattambi",
    "Sankaramangalam",
    "Amayur",
    "Koppam",
    "Vilayur",
    "Kooraachippadi",
    "Naduvattam",
    "Thiruvegappura",
    "Paruthur",
    "Kulamukk",
    "Muthuthala",
    "Perumudiyoor",
    "Total"
  ];

  return (
    <>
      <table className="w-full border-collapse">
        <thead>
          <tr className="font-bold bg-[#e0e2ed]">
            <td className="py-2 px-3 text-center">Sr No</td>
            <td className="py-2 px-3">Place</td>
            <td className="py-2 px-3 text-center">A+ve</td>
            <td className="py-2 px-3 text-center">O+ve</td>
            <td className="py-2 px-3 text-center">B+ve</td>
            <td className="py-2 px-3 text-center">AB+ve</td>
            <td className="py-2 px-3 text-center">A-ve</td>
            <td className="py-2 px-3 text-center">O-ve</td>
            <td className="py-2 px-3 text-center">B-ve</td>
            <td className="py-2 px-3 text-center">AB-ve</td>
            <td className="py-2 px-3 text-center">Total</td>
          </tr>
        </thead>
        <tbody>
          {places?.map((place, index) => (
            <SingleRow key={index} place={place} index={index} />
          ))}
        </tbody>
      </table>
    </>
  );
}

export default Summary;


