import React from "react";
import './LeftBanner.css'

import BloodUrl from '../../assets/image.svg'


function LeftBanner() {
  return (
    <div className="LoginImage">
      <img src={BloodUrl} alt="Banner" />
    </div>
  );
}

export default LeftBanner;
