import React from "react";

function DetailsSingleRow({donarData,edituser}) {
  return (
    <>
      <div className="singleRow">
        <div className="name">
          <span>{donarData?.name}</span>
        </div>
        <div className="place">
          <span>{donarData?.place}</span>
        </div>
        <div className="bloodGroup">
          <span>{donarData?.blood_group}</span>
        </div>
        <div className="phoneNumber">
          <span>{donarData?.number}</span>
        </div>
        <div className="action">
          <span 
          onClick={()=> edituser(donarData)}
          className="edit">Edit</span>
        </div>
      </div>
    </>
  );
}

export default DetailsSingleRow;
