import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <>
      <div className="footer">
       <span> Copyright © 2023 DYFI Pattambi. All Rights Reserved.</span>
    
        <a href="https://wa.me/919633254338"> Powered by StoryN</a>
      </div>
    </>
  );
}

export default Footer;
