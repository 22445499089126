import React from 'react'
import Footer from '../../Components/Footer/Footer'
import Header from '../../Components/Header/Header'
import SearchContent from '../../Components/SearchContent/SearchContent'

function Search({setBloodGroup, bloodGroup, setplace, place,isLoggedIn,LogOutBtnClick}) {
  return (
    <>
    <div className="d-flex flex-column min-vh-100">
      <Header isLoggedIn={isLoggedIn} LogOutBtnClick={LogOutBtnClick}/>
      <SearchContent 
      bloodGroup={bloodGroup} 
      setBloodGroup={setBloodGroup}
      setplace={setplace}
      place={place}
      />
      <Footer />
    </div>
    </>
  )
}

export default Search