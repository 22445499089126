import { gql } from "@apollo/client";

export const GET_DONOR_DATA = gql`
query GetDonarData($place: String!, $bloodGroup:String! ) {
  donarData(where: {place: {_eq: $place}, blood_group: {_eq: $bloodGroup}}) {
    name
    number
    available
    blood_group
    id
    place
  }
  }
`;

export const ADD_DONOR_DATA = gql`
mutation AddDonorData($place: String!, $bloodGroup:String!, $name:String!, $number:numeric! ) {
  insert_donarData(objects: {available: true, blood_group: $bloodGroup, name: $name, number: $number, place: $place}) {
    returning {
      name
    }
  }
}
`;

export const ALL_DONOR_DATA = gql`
query AllDonorData($name:String! ) {
  donarData(where: {name: {_ilike: $name}}) {
    name
    available
    blood_group
    id
    number
    place
  }
}
`;

export const DELETE_USER = gql`
mutation deleteuser($id:uuid!) {
  delete_donarData(where: {id: {_eq: $id}}) {
    returning {
      name
    }
  }
}
`;

export const EDIT_USER = gql`
mutation EditUser($id:uuid!, $name:String!, $number: numeric!, $place: String!, $bloodGroup:String!) {
  update_donarData(where: {id: {_eq: $id}}, _set: {name: $name, number: $number, place: $place, blood_group: $bloodGroup}) {
    returning {
      name
    }
  }
}
`;


export const SUMMARY = gql`
query Summary($place: String!, $bloodGroup:String!) {
  donarData(where: {place: {_ilike: $place}, blood_group: {_ilike: $bloodGroup}}) {
    name
    blood_group
  }
}
`;