import React, { useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import AllDetails from "../../Components/AllDetails/AllDetails";
import EditUserPopup from "../../Components/EditUserPopup/EditUserPopup";

import { useMutation, useQuery } from "@apollo/client";
import {
  DELETE_USER,
  ALL_DONOR_DATA,
  EDIT_USER,
} from "../../gql_queries/Queries";

function Dashboard({ isLoggedIn, LogOutBtnClick }) {
  const [popupShow, setPopupShow] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [selectedData, setSelectedData] = useState();
  const deleteUser = useMutation(DELETE_USER);
  const updateUser = useMutation(EDIT_USER);
  const edituser = (donarData) => {
    setSelectedData(donarData);
    setPopupShow(true);
  };
  const { loading, data, refetch } = useQuery(ALL_DONOR_DATA, {
    variables: {
      name: "%" + searchData + "%",
    },
  });
  const DeleteUserClick = () => {
    deleteUser[0]({
      variables: {
        id: selectedData?.id,
      },
    });
    refetch();
    setPopupShow(false);
  };
  const EditUserClick = (Data) => {
    updateUser[0]({
      variables: {
        id: Data?.id,
        name: Data?.fullName,
        number: Data?.phoneNumber,
        place: Data?.location,
        bloodGroup: Data?.bloodGroup,
      },
    });
    refetch();
    setPopupShow(false);
  };
  return (
    <>
      {popupShow && (
        <EditUserPopup
          selectedData={selectedData}
          setPopupShow={setPopupShow}
          DeleteUserClick={DeleteUserClick}
          EditUserClick={EditUserClick}
        />
      )}
      <div className="d-flex flex-column min-vh-100">
        <Header isLoggedIn={isLoggedIn} LogOutBtnClick={LogOutBtnClick} />
        <div className="container flex-1">
          <AllDetails
            userDetails={data}
            loading={loading}
            edituser={edituser}
            setPopupShow={setPopupShow}
            setSearchData={setSearchData}
            searchData={searchData}
          />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Dashboard;
