import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function LoginForm({pswd,user}) {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  let navigate = useNavigate();
  const LoginBtnClick = ()=> {
    if (userName === user && password === pswd) {
      localStorage.setItem('user', JSON.stringify(userName));
      navigate(`/`);
    }else {
      alert('login Failed')
    }

  }

  return (
    <>
      <div className="flex flex-1 justify-center">
        <div className="flex flex-col items-center w-full max-w-sm px-4">
          <h3 className="font-bold text-lg text-black mb-8">Admin login</h3>
          <div className="w-full py-2">
            <label className="font-bold text-base mb-2">User Name</label>
            <input
              className="w-full border border-solid rounded-md py-2 px-3 border-dark"
              type="text"
              placeholder="Enter your username"
              value={userName}
              onChange={(e)=>setUserName(e.target.value)}
            ></input>
          </div>
          <div className="w-full py-2">
            <label className="font-bold text-base mb-2">Password</label>
            <input
              className="w-full border border-solid rounded-md py-2 px-3 border-dark"
              type="password"
              placeholder="********"
              value={password}
              onChange={(e)=>setPassword(e.target.value)}
            ></input>
          </div>
          <div className="w-full py-2">
            <button className="w-full mt-2 py-2 px-3 text-center bg-[#0E0D33] text-white rounded"
            type="submit"
            onClick={LoginBtnClick}
            >Login</button>
          </div>
          <Link to="/add-donor" className="donorLink">
              Are you a Doner?
            </Link>
        </div>
      </div>
    </>
  );
}

export default LoginForm;
